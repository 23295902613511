#editor {
  display: flex;
  height: calc(100vh - 120px);
  overflow: hidden;

  #input,
  #output {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

#output {
  border-right: $border;
  line-height: 2;
}

textarea#input {
  border: 0;
  font-family: $font;
  resize: none;
  line-height: 2;
}
