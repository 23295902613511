html {
  box-sizing: border-box;
  font-family: font;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  direction: rtl;
  font-family: $font;
  overflow: hidden;

  &.viewer-page {
    overflow: auto;

    #app {
      height: auto !important;
    }
  }
}

.button {
  font-family: $font;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;

  header {
    height: 70px;
    border-bottom: $border;
    background: #fafafa;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      margin-right: 5px;
    }

    .right {
      display: flex;
    }

    img#logo {
      margin-left: 20px;
    }

    input#title {
      border: 1px solid #dadce0;
      background: transparent;
      padding: 10px 15px;
      font-family: $font;
    }

    h3#title {
      background: transparent;
      padding: 10px 15px;
      font-family: $font;
      font-weight: 500;
    }
  }

  footer {
    height: 50px;
    border-top: $border;
    background: #fafafa;
    padding: 20px;
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    color: #bbb;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
