#output {
  a {
    color: #4183c4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    line-height: 1.4;
    cursor: text;
  }
  h1:hover a.anchor,
  h2:hover a.anchor,
  h3:hover a.anchor,
  h4:hover a.anchor,
  h5:hover a.anchor,
  h6:hover a.anchor {
    text-decoration: none;
  }
  h1 tt,
  h1 code {
    font-size: inherit;
  }
  h2 tt,
  h2 code {
    font-size: inherit;
  }
  h3 tt,
  h3 code {
    font-size: inherit;
  }
  h4 tt,
  h4 code {
    font-size: inherit;
  }
  h5 tt,
  h5 code {
    font-size: inherit;
  }
  h6 tt,
  h6 code {
    font-size: inherit;
  }
  h1 {
    padding-bottom: 0.3em;
    font-size: 2.25em;
    line-height: 1.2;
    border-bottom: 1px solid #eee;
  }
  h2 {
    padding-bottom: 0.3em;
    font-size: 1.75em;
    line-height: 1.225;
    border-bottom: 1px solid #eee;
  }
  h3 {
    font-size: 1.5em;
    line-height: 1.43;
  }
  h4 {
    font-size: 1.25em;
  }
  h5 {
    font-size: 1em;
  }
  h6 {
    font-size: 1em;
    color: #777;
  }
  p,
  blockquote,
  ul,
  ol,
  dl,
  table {
    margin: 0.8em 0;
  }
  li > ol,
  li > ul {
    margin: 0 0;
  }
  hr {
    height: 4px;
    padding: 0;
    margin: 16px 0;
    background-color: #e7e7e7;
    border: 0 none;
    overflow: hidden;
    box-sizing: content-box;
    border-bottom: 1px solid #ddd;
  }

  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }

  body > h2:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  body > h1:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  body > h1:first-child + h2 {
    margin-top: 0;
    padding-top: 0;
  }
  body > h3:first-child,
  body > h4:first-child,
  body > h5:first-child,
  body > h6:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  a:first-child h1,
  a:first-child h2,
  a:first-child h3,
  a:first-child h4,
  a:first-child h5,
  a:first-child h6 {
    margin-top: 0;
    padding-top: 0;
  }
  h1 p,
  h2 p,
  h3 p,
  h4 p,
  h5 p,
  h6 p {
    margin-top: 0;
  }
  li p.first {
    display: inline-block;
  }
  ul,
  ol {
    padding-right: 30px;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  ul:first-child,
  ol:first-child {
    margin-top: 0;
  }
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }
  blockquote {
    border-right: 4px solid #dddddd;
    padding: 0 15px;
    color: #777777;
  }
  blockquote blockquote {
    padding-left: 0;
  }
  table {
    padding: 0;
    word-break: initial;
  }
  table tr {
    border-top: 1px solid #cccccc;
    margin: 0;
    padding: 0;
  }
  table tr:nth-child(2n) {
    background-color: #f8f8f8;
  }
  table tr th {
    font-weight: bold;
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }
  table tr td {
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
  }
  table tr th:first-child,
  table tr td:first-child {
    margin-top: 0;
  }
  table tr th:last-child,
  table tr td:last-child {
    margin-bottom: 0;
  }

  .CodeMirror-gutters {
    border-right: 1px solid #ddd;
  }

  .md-fences,
  tt {
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 0;
    font-family: Consolas, "Liberation Mono", Courier, monospace;
    padding: 2px 4px 0px 4px;
    font-size: 0.9em;
  }

  code {
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 0;
    font-family: Consolas, "Liberation Mono", Courier, monospace;
    padding: 2px 4px 0px 4px;
    font-size: 0.9em;
  }

  pre {
    padding: 10px;

    code {
      border: 0;
      background-color: transparent;
      border-radius: 0;
      padding: 0;
    }
  }

  .md-fences {
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 0.2em 1em;
    padding-top: 8px;
    padding-bottom: 6px;
  }
  .task-list {
    padding-left: 0;
  }

  .task-list-item {
    padding-left: 32px;
  }

  .task-list-item input {
    top: 3px;
    left: 8px;
  }

  @media print {
    html {
      font-size: 13px;
    }
    table,
    pre {
      page-break-inside: avoid;
    }
    pre {
      word-wrap: break-word;
    }
  }

  .md-fences {
    background-color: #f8f8f8;
  }
  #write pre.md-meta-block {
    padding: 1rem;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f7f7f7;
    border: 0;
    border-radius: 3px;
    color: #777777;
    margin-top: 0 !important;
  }

  .mathjax-block > .code-tooltip {
    bottom: 0.375rem;
  }

  #write > h3.md-focus:before {
    left: -1.5625rem;
    top: 0.375rem;
  }
  #write > h4.md-focus:before {
    left: -1.5625rem;
    top: 0.285714286rem;
  }
  #write > h5.md-focus:before {
    left: -1.5625rem;
    top: 0.285714286rem;
  }
  #write > h6.md-focus:before {
    left: -1.5625rem;
    top: 0.285714286rem;
  }
  .md-image > .md-meta {
    border: 1px solid #ddd;
    border-radius: 3px;
    font-family: Consolas, "Liberation Mono", Courier, monospace;
    padding: 2px 4px 0px 4px;
    font-size: 0.9em;
    color: inherit;
  }

  .md-tag {
    color: inherit;
  }

  .md-toc {
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .sidebar-tabs {
    border-bottom: none;
  }

  #typora-quick-open {
    border: 1px solid #ddd;
    background-color: #f8f8f8;
  }

  #typora-quick-open-item {
    background-color: #fafafa;
    border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
    border-style: solid;
    border-width: 1px;
  }

  #md-notification:before {
    top: 10px;
  }

  /** focus mode */
  .on-focus-mode blockquote {
    border-right-color: rgba(85, 85, 85, 0.12);
  }

  header,
  .context-menu,
  .megamenu-content,
  footer {
    font-family: "Segoe UI", "Arial", sans-serif;
  }

  .file-node-content:hover .file-node-icon,
  .file-node-content:hover .file-node-open-state {
    visibility: visible;
  }

  .mac-seamless-mode #typora-sidebar {
    background-color: #fafafa;
    background-color: var(--side-bar-bg-color);
  }

  .md-lang {
    color: #b4654d;
  }

  pre,
  code {
    direction: ltr !important;
    unicode-bidi: embed;
  }
}

input[type="checkbox"][readonly] {
  margin-left: 7px;
  vertical-align: middle;
}
